import React from 'react'
import Link from 'next/link'
import { truncateString, THEME } from 'utils'
import Rating from 'components/Rating'
import UserMeta from 'components/UserMeta'

const CHARACTER_COUNT = 28

const ReviewCard = ({ review, featured = false, isDave = false, hideVenue = false, feedItem = false }) => {
  return (
    <>
      <Link
        href='/restaurant/[restaurantSlug]/review/[reviewSlug]'
        as={`/restaurant/${review.venue.slug}/review/${review.id}`}
      >
        <a>
          <div
            className={`reviewCard ${featured ? 'reviewCard--featured' : ''} ${feedItem ? 'reviewCard--feedItem' : ''}`}
          >
            <div className='reviewCard__imageContainer'>
              <div className='reviewCard__image' style={{ backgroundImage: `url(${review.media.thumbnails.large})` }} />
              <div className='reviewCard__rating'>
                <Rating score={review.score} size={featured || feedItem ? '60' : '42'} isDave={isDave} />
              </div>
            </div>
            <div className='reviewCard__details'>
              {!hideVenue && (
                <div>
                  <h2 className='reviewCard__title'>
                    {featured ? review.venue.name : truncateString(review.venue.name, CHARACTER_COUNT)}
                  </h2>
                  <p className='reviewCard__location'>{`${review.venue.city}, ${review.venue.state}`}</p>
                </div>
              )}
              {(featured || feedItem) && <UserMeta user={review.user} date={review.date} />}
            </div>
          </div>
        </a>
      </Link>
      <style jsx>{`
        .reviewCard {
          margin-bottom: 30px;
          cursor: pointer;
        }
        .reviewCard__imageContainer {
          position: relative;
          overflow: hidden;
          padding-top: 56.25%;
          margin-bottom: 6px;
          background-color: ${THEME.COLORS.LIGHTGRAY};
        }
        .reviewCard__image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0;
          display: block;
          width: 100%;
          background-size: cover;
          background-position: center;
        }
        .reviewCard--featured .reviewCard__image img {
          margin-bottom: 12px;
        }
        .reviewCard__rating {
          position: absolute;
          right: 10px;
          bottom: 4px;
          z-index: 2;
        }
        .reviewCard__details {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .reviewCard__title {
          color: #000;
          font-weight: bold;
          margin: 0;
          font-size: 14px;
          line-height: 18px;
        }
        .reviewCard--featured .reviewCard__title {
          font-size: 20px;
          line-height: 25px;
        }
        .reviewCard__location {
          color: #222;
          letter-spacing: 0.1px;
          font-size: 12px;
          line-height: 15px;
          margin: 0;
        }
        .reviewCard--featured .reviewCard__location {
          font-size: 16px;
          line-height: 25px;
        }
        .reviewCard--feedItem .reviewCard__title {
          font-size: ;
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}) {
          .reviewCard--feedItem .reviewCard__title {
            font-size: 20px;
            line-height: 25px;
          }
          .reviewCard--feedItem .reviewCard__image img {
            margin-bottom: 12px;
          }
        }
      `}</style>
    </>
  )
}

export default ReviewCard
