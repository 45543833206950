import React from 'react'
import { wordsOrDate } from 'utils'
import VerifiedIcon from 'icons/Verified'

const UserMeta = ({ user, date = false }) => {
  return (
    <>
      <div className='userMeta'>
        <img className='userMeta__image' src={user.imageUrl} alt={`${user.username} on One Bite Pizza App`} />
        <div className='userMeta__info'>
          <p className='userMeta__username'>
            {user.username}
            {user.type === 'DAVE' || user.type === 'CRITIC' ? (
              <i className='userMeta__verified'>
                <VerifiedIcon width='12px' height='12px' />
              </i>
            ) : null}
          </p>
          {date && <p className='userMeta__timestamp'>{wordsOrDate(date)}</p>}
        </div>
      </div>
      <style jsx>{`
        .userMeta {
          display: flex;
          align-items: center;
        }
        .userMeta__image {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          display: block;
          margin-right: 10px;
        }
        .userMeta__username,
        .userMeta__timestamp {
          margin: 0;
        }
        .userMeta__username {
          display: flex;
          align-items: center;
          color: #000;
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 0.23px;
          line-height: 16px;
        }
        .userMeta__verified {
          margin-left: 4px;
          display: flex;
          align-items: center;
        }
        .userMeta__timestamp {
          color: #757575;
          font-size: 12px;
          letter-spacing: 0.1px;
          line-height: 15px;
        }
      `}</style>
    </>
  )
}

export default UserMeta
