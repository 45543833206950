import React from 'react'
import { THEME, formatScore } from 'utils'

const Rating = ({ score, size, isDave = false }) => {
  return (
    <>
      <div className={`rating ${isDave ? 'rating--dave' : ''}`}>
        {isDave ? (
          <img className='rating__icon rating__icon--dave' src='/static/images/dave.png' alt='Pizza review' />
        ) : (
          <img className='rating__icon' src='/static/images/pizza.png' alt='Pizza review' />
        )}
        <p className='rating__score'>{formatScore(score)}</p>
      </div>
      <style jsx>{`
        .rating {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: ${size}px;
          width: ${size}px;
          height: ${size}px;
          border-radius: 50%;
          border: 2px solid ${THEME.COLORS.PRIMARY};
          background: #fff;
        }
        .rating__icon {
          height: 35%;
          width: auto;
        }
        .rating__score {
          font-size: 0.3125em;
          color: ${THEME.COLORS.PRIMARY};
          margin: 0.15em 0 0;
          line-height: 1;
          font-weight: 500;
          letter-spacing: -0.5px;
        }
        .rating--dave .rating__icon {
          height: 38%;
        }
        .rating--dave .rating__score {
          margin-top: 0.1em;
        }
      `}</style>
    </>
  )
}

export default Rating
