import React from 'react'

export default function VerifiedIcon({ width = '18px', height = '18px' }) {
  return (
    <svg viewBox='0 0 12 12' className='svgIcon socialIcon iconVerified' style={{ width, height }}>
      <title>Verified</title>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-587.000000, -427.000000)'>
          <g transform='translate(587.000000, 427.000000)'>
            <g transform='translate(-2.000000, -2.000000)'>
              <path
                d='M8,12.9291295 L6.54312588,13.5398276 C6.3148215,13.6355291 6.05085715,13.5497619 5.92240696,13.3381438 L5.10273039,11.9877495 L5.10273039,11.9877495 L3.56513512,11.6254855 C3.32418105,11.5687157 3.16104212,11.3441743 3.18150973,11.0974705 L3.3121193,9.52318477 L3.3121193,9.52318477 L2.28111201,8.32633125 C2.11954452,8.13877427 2.11954452,7.86122573 2.28111201,7.67366875 L3.3121193,6.47681523 L3.3121193,6.47681523 L3.18150973,4.90252951 C3.16104212,4.65582574 3.32418105,4.43128425 3.56513512,4.37451445 L5.10273039,4.0122505 L5.10273039,4.0122505 L5.92240696,2.66185621 C6.05085715,2.45023811 6.3148215,2.36447089 6.54312588,2.46017241 L8,3.07087054 L8,3.07087054 L9.45687412,2.46017241 C9.6851785,2.36447089 9.94914285,2.45023811 10.077593,2.66185621 L10.8972696,4.0122505 L10.8972696,4.0122505 L12.4348649,4.37451445 C12.6758189,4.43128425 12.8389579,4.65582574 12.8184903,4.90252951 L12.6878807,6.47681523 L12.6878807,6.47681523 L13.718888,7.67366875 C13.8804555,7.86122573 13.8804555,8.13877427 13.718888,8.32633125 L12.6878807,9.52318477 L12.6878807,9.52318477 L12.8184903,11.0974705 C12.8389579,11.3441743 12.6758189,11.5687157 12.4348649,11.6254855 L10.8972696,11.9877495 L10.8972696,11.9877495 L10.077593,13.3381438 C9.94914285,13.5497619 9.6851785,13.6355291 9.45687412,13.5398276 L8,12.9291295 L8,12.9291295 Z'
                id='Star'
                fill='#F2BE35'
                transform='translate(8.000000, 8.000000) rotate(16.000000) translate(-8.000000, -8.000000) '
              ></path>
              <g transform='translate(5.000000, 6.000000)' fill='#FFFFFF'>
                <polygon
                  transform='translate(3.606092, 2.142857) rotate(-45.000000) translate(-3.606092, -2.142857) '
                  points='1.46323438 1.71428571 5.74894867 1.71428571 5.74894867 2.57142857 1.46323438 2.57142857'
                ></polygon>
                <polygon
                  transform='translate(1.285714, 3.251051) rotate(45.000000) translate(-1.285714, -3.251051) '
                  points='7.13714802e-17 2.8224799 2.57142857 2.8224799 2.57142857 3.67962276 0 3.67962276'
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
